<template>
    <div class="lms-ex-layout-header-container">
        <div class="lms-ex-layout-header-border" />
        <div class="lms-ex-layout-header-title">
            <div class="lms-ex-layout-left-align">
                <img class="lms-ex-bizmates-logo" src="../assets/svg/bizmates.svg" alt="bizmates" />
                <div class="lms-ex-title">Learning Management System</div>
            </div>
            <div class="lms-ex-layout-right-align">
                <AppButton color="black" v-if="$route.name !== 'login'" @click="$refs.logoutModal.showModal()">
                    ログアウト <font-awesome-icon icon="fa-solid fa-sign-out" size="lg" />
                </AppButton>
            </div>
        </div>
    </div>
    <AppModal title="確認" ref="logoutModal">
        <template #modal-body>
            <p>ログアウトしますか？</p>
        </template>
        <template #modal-footer>
            <div class="button-container">
                <AppButton @click="$refs.logoutModal.hideModal()" color="white">
                    <template #button-text>キャンセル</template>
                </AppButton>
                <AppButton @click="logout">
                    <template #button-text>ログアウト</template>
                </AppButton>
            </div>
        </template>
    </AppModal>
</template>

<script>
import { deleteTokenCookie } from '@/utils/common';

export default {
    name: 'AppLayoutHeader',
    methods: {
        logout() {
            deleteTokenCookie('lms_token');
            this.$router.push('/');
            this.$refs.logoutModal.hideModal();
        }
    }
};
</script>

<style scoped>
.lms-ex-layout-header-container {
    background: #ffffff;
    height: 104px;
    position: fixed;
    width: 100%;
    z-index: 2;
}
.lms-ex-layout-header-border {
    background: #0087d2;
    height: 8px;
}
.lms-ex-layout-header-title {
    box-sizing: border-box;
    display: flex;
    height: 96px;
    justify-content: space-between;
    margin: auto;
    max-width: 1366px;
    padding: 24px 171px;
    width: 100%;
}
.lms-ex-bizmates-logo {
    height: 31px;
    margin-top: 8px;
    width: 160px;
}
.lms-ex-title {
    color: #0f0a14;
    font: 30px/45px 'NotoSansJP-Bold';
    letter-spacing: 0px;
    line-height: 45px;
    margin-left: 21px;
    margin-top: 2px;
    text-align: center;
}

.lms-ex-layout-left-align {
    display: flex;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 24px;
}

:deep(.modal-footer) {
    padding: 24px 0;
    width: 100%;
}

@media screen and (max-width: 1280px) {
    .lms-ex-layout-header-title {
        padding: 24px 8%;
    }
}

@media screen and (max-width: 940px) {
    .lms-ex-layout-left-align {
        flex-direction: column;
    }
    .lms-ex-layout-left-align .lms-ex-title {
        font-size: 1.3rem;
        line-height: 30px;
        margin: 0;
    }
}
</style>
