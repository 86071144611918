import { createWebHistory, createRouter } from "vue-router";
import { getTokenCookie } from '@/utils/common';

const routes = [
    { 
        path: '/:pathMatch(.*)', 
        component: () => import("@/modules/error/Page404"),
    }, 
    {   
        path: '/404', 
        component: () => import("@/modules/error/Page404"),
    },
    {   
        name: 'home', 
        path: '/home',
        component: () => import("@/modules/top-page/index"),
    },
    {   
        path: '/ex-modal', 
        component: () => import("@/modules/exchange-students/StudentReplacementModal"),
    },
    {
        path: '', 
        name: 'login',
        component: () => import("@/modules/login"),

    },
    {   
        path: '/sample', 
        component: () => import("@/modules/sample/index"),
    },
    {   
        path: '/modal',
        component: () => import("@/modules/sample/modal")
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    const token =  getTokenCookie('lms_token');
    if (to.name !== "login" && !token){
        return next({ name: "login" });
    } else if (to.name == 'login' && token){
        return next({ name: "home" });
    } else {
        return next();
    }
});

export default router;
