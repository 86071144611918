import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'; 
import { createApolloProvider } from '@vue/apollo-option';
import { setContext } from 'apollo-link-context';
import { lmsGraphQlEndpoint } from '@/utils/constants';
import { getTokenCookie } from '@/utils/common';

// todo: move to env. has conflicts on AWS loading of env
const VUE_APP_AUTH_TOKEN = 'lms_token';

let unauthenticatedRequestLink, authenticatedRequestLink;
unauthenticatedRequestLink = authenticatedRequestLink = createHttpLink({
  uri: lmsGraphQlEndpoint, 
});

// Apollo Client for Unauthenticated request
const unauthenticatedRequest = new ApolloClient({
  link: unauthenticatedRequestLink,
  cache: new InMemoryCache(),
  onError: ({ networkError, graphQLErrors }) => {
    console.log('graphQLErrors', graphQLErrors)
    console.log('networkError', networkError)
  }
});

// Apollo Client for Authenticated request
const authenticationHeaders = setContext((_, { headers }) => {
  const token = getTokenCookie(VUE_APP_AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const authenticatedRequest = new ApolloClient({
  link: authenticationHeaders.concat(authenticatedRequestLink),
  cache: new InMemoryCache(),
  onError: ({ networkError, graphQLErrors }) => {
    console.log('graphQLErrors', graphQLErrors)
    console.log('networkError', networkError)
  }
});


// Apollo Providers 
const apolloProvider = createApolloProvider({
  clients: {
    unauthenticatedRequest,
    authenticatedRequest
  },
  defaultClient: authenticatedRequest,
});

export default apolloProvider;
