<template>
    <Transition name="fade" v-show="modalState">
        <div class="lms-ex-app-error-notification-modal-container">
            <div class="error-notification" :data-count="errors.length">
                <div class="error-header" />
                <div class="error-body">
                    <div class="error-title">
                        <font-awesome-icon icon="fa-solid fa-triangle-exclamation" class="alertIcon" />
                        <div class="error-title-text">エラー</div>
                    </div>
                    <div class="error-message" v-for="error in errors" :key="error">
                        {{ error }}
                    </div>
                    <button class="error-button" @click="hideModal()">OK</button>
                </div>
                <div class="error-footer" />
            </div>
        </div>
    </Transition>
</template>

<script>
import { deleteTokenCookie } from '@/utils/common';
import { JA_ERROR_MESSAGES } from '@/utils/constants';

export default {
    name: 'AppErrorNotificationModal',

    data() {
        return {
            errors: [],
            modalState: false,
            unauthenticated: false,
        }
    },

    methods: {
        showModal() {
            this.modalState = true;
            document.querySelector('body').style.overflow = 'hidden';
        },

        hideModal() {
            this.errors = [];
            this.modalState = false;
            document.querySelector('body').style.overflow = 'auto';

            // todo: fix vue-apollo.js and handle authentication process there.
            if (this.unauthenticated) {
                deleteTokenCookie('lms_token');
                location.reload();
            }
        },

        setErrorNotification(error) {
            let errorMessage = null;

            if (error.message === 'Unauthenticated.') {
                this.unauthenticated = true;
                errorMessage = JA_ERROR_MESSAGES[error.message];
            } else {
                let message = error.graphQLErrors ?
                    error.graphQLErrors[0].debugMessage :
                    error.message;

                errorMessage = JA_ERROR_MESSAGES[message] || JA_ERROR_MESSAGES.defaultError;
            }

            this.errors.push(errorMessage);
            this.showModal();
        },
    },
};
</script>

<style scoped>
.alertIcon {
    margin-top: 6px;
}
.lms-ex-app-error-notification-modal-container {
    align-items: center;
    background-color: #00000080;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
}
.error-notification {
    background: #fff;
    max-height: 468px;
    max-width: 912px;
    min-height: 468px;
    padding-left: 88px;
    padding-right: 88px;
    width: calc(77% - 50px);
}
.error-notification[data-count='1'] {
    height: 352px;
    max-width: 536px;
    min-height: 352px;
    width: 360px;
}
.error-header {
    height: 88px;
    width: 100%;
}
.error-body {
    color: #ff0000;
    font-family: 'NotoSansJP-Regular';
    font-size: 24px;
    height: 292px;
    letter-spacing: 0px;
    line-height: 36px;
    opacity: 1;
    overflow: auto;
    text-align: center;
}
.error-title {
    display: flex;
    justify-content: center;
}
.error-titleText {
    margin-left: 16px;
}
.error-message {
    margin-top: 16px;
    width: 100%;
}
.error-button {
    background: #0087d2 0% 0% no-repeat padding-box;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    color: #ffffff;
    font-family: 'NotoSansJP-Medium';
    font-size: 16px;
    height: 48px;
    letter-spacing: 0px;
    line-height: 24px;
    margin-top: 40px;
    opacity: 1;
    text-align: center;
    width: 280px;
}
.error-footer {
    height: 88px;
    width: 100%;
}
::-webkit-scrollbar-thumb {
    background: var(--blue);
}

::-webkit-scrollbar {
    height: 88px;
    width: 8px;
}
@-moz-document url-prefix() {
    .error-body {
        scrollbar-color: var(--blue) var(--dwhite);
        scrollbar-width: thin;
    }
}
</style>
