<template>
    <div class="lms-ex-layout-footer-container">
        <div class="lms-ex-layout-footer-title-container">
            <div class="lms-ex-layout-footer-title">
                <img class="lms-ex-footer-bizmates-logo" src="../assets/svg/bizmates.svg" alt="bizmates" />
                <div class="lms-ex-footer-title">Learning Management System</div>
            </div>
            <div class="lms-ex-footer-all-rights-reserved">© Bizmates, Inc. all rights reserved.</div>
        </div>
        <div class="lms-ex-layout-footer-border" />
    </div>
</template>

<script>
export default {
    name: 'AppLayoutFooter',
};
</script>

<style scoped>
.lms-ex-layout-footer-container {
    background: #ffffff;
    height: 158px;
    width: 100%;
}
.lms-ex-layout-footer-border {
    background: #0087d2;
    height: 32px;
}
.lms-ex-layout-footer-title-container {
    box-sizing: border-box;
    display: flex;
    height: 158px;
    justify-content: space-between;
    margin: auto;
    max-width: 1366px;
    padding: 48px 171px;
}
.lms-ex-footer-bizmates-logo {
    height: 34px;
    width: 173px;
}
.lms-ex-footer-title {
    color: #0f0a14;
    font: 14px/20px 'NotoSansJP-Bold';
    margin-left: 2px;
    margin-top: 8px;
}
.lms-ex-footer-all-rights-reserved {
    color: #0f0a14;
    font: 12px/18px 'NotoSansJP-Regular';
}

@media screen and (max-width: 1280px) {
    .lms-ex-layout-footer-title-container {
        padding: 48px 8%;
    }
}
</style>
