import { createApp } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { registerComponents } from './global-components';

import apolloProvider from "./vue-apollo";
import App from './App.vue';
import axios from "axios";
import ElementPlus from "element-plus";
import router from "./router/index";
import store from "./store";
import VeeValidatePlugin from "./plugin/validation";
import VueAxios from 'vue-axios'

import './assets/css/main.css';
import './config/fontawesome';
import "element-plus/theme-chalk/index.css";

const app = createApp(App);

registerComponents(app)

app.component('font-awesome-icon', FontAwesomeIcon)
    .use(router)
    .use(apolloProvider)
    .use(ElementPlus)
    .use(store)
    .use(VeeValidatePlugin)
    .use(VueAxios, axios)
    .mount('#app');
