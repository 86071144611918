<template>
  <layout>
      <router-view :key="$route.path" />
  </layout>
</template>

<script>
import Layout from './layouts/AppLayout';

export default {
  name: 'App',

  components: {
      Layout,
  },
};
</script>
