<template>
    <div class="lms-ex-app-input-container">
        <label v-if="label" style="margin-left: 2px; margin-bottom: 2px">
            {{ label }}
        </label>
        <input
            :class="['lms-ex-app-input', { 'bg-color--green': bgColorGreen, 'error-boarder': inputInvalid }]"
            :disabled="disabled"
            :placeholder="placeholder"
            :type="type"
            :value="modelValue"
            @blur="(event) => $emit('blur', event)"
            @change="(event) => $emit('change', event.target.value)"
            @input="updateInput"
            :autocomplete="autocomplete"
        />
    </div>
</template>
  
<script>
export default {
    name: "AppInput",

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: "",
        },
        modelValue: {
            type: [String, Number, Boolean],
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "text",
        },
        bgColor: {
            type: String,
            default: '',
        },
        invalid: {
            type: Boolean,
            default: false,
        },
        autocomplete: {
            type: String,
            default: "",
        }
    },
    computed: {
        bgColorGreen() {
            return (this.bgColor === 'green');
        },

        inputInvalid() {
            return this.invalid == true;
        },
    },
    methods: {
        updateInput(event) {
            this.$emit("update:modelValue", event.target.value);
        }
    }
};
</script>

<style scoped>
.lms-ex-app-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.lms-ex-app-input {
    border: #cccccc solid 1px;
    box-sizing: border-box;
    color: #000000;
    font-family: 'NotoSansJP-Regular';
    font-stretch: normal;
    font-style: normal;
    line-height: 21px;
    margin: auto;
    padding: 4px 10px;
    width: 100%;
}
.bg-color--green {
    background-color: #e7f4e4;
}
.error-boarder {
    border: var(--red) solid 2px;
}
</style>
