// Import all common components here

import AppButton from './components/AppButton';
import AppErrorNotificationModal from './components/AppErrorNotificationModal';
import AppInput from './components/AppInput';
import AppModal from './components/AppModal';

let components = {
    AppButton,
    AppErrorNotificationModal,
    AppInput,
    AppModal
}

export const registerComponents = app => Object.keys(components).forEach(key => {
    app.component(key, components[key])
})
