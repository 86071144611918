import { createStore } from 'vuex'
import studentExchange from './modules/studentExchange';

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {studentExchange}
});
