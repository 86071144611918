<template>
    <Transition name="fade" v-show="modalState">
        <div class="lms-ex-modal-overlay">
            <div class="modal">
                <div class="modal-header">
                    <span class="modal-title">{{ title }}</span>
                    <span @click="hideModalAndResetForm()">
                        <img width="36" src="../assets/svg/close.svg" />
                    </span>
                </div>
                <div class="modal-body">
                    <slot name="modal-body" />
                </div>
                <div class="modal-footer stack-top footer-opacity">
                    <slot name="modal-footer" />
                </div>
            </div>
        </div>
    </Transition>
</template>
  
<script>
export default {
    name: "AppModal",

    data() {
        return {
            modalState: false,
        }
    },

    props: {
        title: {
            type: String,
            default: ""
        },
    },

    methods: {
        showModal() {
            this.modalState = true;
            document.querySelector('body').style.overflow = 'hidden';
        },

        hideModalAndResetForm() {
            this.$emit('resetForm');
            this.hideModal();
        },

        hideModal() {
            this.modalState = false;
            document.querySelector('body').style.overflow = 'auto';
        },
    },
}
</script>

<style scoped>
.lms-ex-modal-overlay {
    align-items: center;
    background-color: #00000080;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
}

.modal {
    background-color: white;
    font-family: 'NotoSansJP-Medium';
    max-height: calc(100vh - 48px);
    max-width: 912px;
    min-height: 265px;
    min-width: 536px;
    position: relative;
}

.modal-header {
    box-shadow: 0px 1px 3px #00000029;
    column-gap: 40px;
    display: flex;
    font-size: 24px;
    justify-content: space-between;
    max-height: 84px;
    text-align: left;
}

.modal-header span {
    margin: 24px 24px 24px 88px;
}

.modal_modal-close-icon {
    color: var(--blue);
    cursor: pointer;
    font-size: 30px;
    font-weight: 0;
}

.modal-body {
    max-height: calc((100vh - 48px) - 123px);
    min-height: 80px;
    overflow: auto;
    padding-bottom: 24px;
    padding-left: 88px;
    padding-right: 88px;
}

.footer-opacity {
    background: rgba(255, 255, 255, 0.8);
    bottom: 0;
    position: absolute;
}
.stack-top {
    z-index: 9;
}

.modal-footer {
    box-shadow: 0px -1px 3px #00000029;
    padding: 24px 160px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

::-webkit-scrollbar-thumb {
    background: var(--blue);
}

::-webkit-scrollbar {
    height: 88px;
    width: 8px;
}

@-moz-document url-prefix() {
    .modal-body {
        scrollbar-color: var(--blue) var(--dwhite);
        scrollbar-width: thin;
    }
}
</style>
