import { padStart } from 'lodash';

function companyDisplayId(id) {
    id = setDisplayId(id);
    return `C${setDisplayId(id)}`;
}

function departmentDisplayId(id) {
    id = setDisplayId(id);
    return `D${setDisplayId(id)}`;
}

function studentDisplayId(id) {
    if (id) {
        let studentId = setDisplayId(id);
        return `S${studentId}`;
    } 
    
    return null;
}

function setDisplayId(id) {
    return padStart(id, 11, 0);
}

function displayIdToId(displayId) {
    if (displayId) {
        displayId = displayId.slice(1);
        return +displayId;
    } 

    return null;
}

export {
    companyDisplayId,
    departmentDisplayId,
    studentDisplayId,
    displayIdToId
};
