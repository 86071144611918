import moment from 'moment';

let setTokenCookie = (name, token) => {
    // var expires = "";
    // if(days) {
    //     var date = new Date();
    //     date.setDate(date.getDate() + days);
    //     expires = "; Expires=" + date.toUTCString();
    // }
    
    document.cookie = name + "=" + (token || "") + "; Secure;";
};

let getTokenCookie = (name) => {
    var cookieArray = document.cookie.split(';');
    for(var i = 0; i < cookieArray.length; i++) {
        if(cookieArray[i].split('=')[0].trim() == name) {
            return cookieArray[i].split('=')[1];
        }
    }
};

function fullNameEnglish(name) {
    const {first_name_en, last_name_en} = name || {};

    return nameFormat(first_name_en, last_name_en);
}

function fullNameKanji(name) {
    const {first_name_kanji, last_name_kanji} = name || {};

    return nameFormat(first_name_kanji, last_name_kanji);
}

function nameFormat(firstName, lastName) {
    return `${lastName || ''} ${firstName || ''}`
}

function dateFormat(date, dateFormat = 'YYYY/MM/DD'){
    return moment(new Date(date)).format(dateFormat);
}

let deleteTokenCookie = (name) => {
    var expires = "";
    var date = new Date();
    date.setDate(date.getDate() - 1);
    expires = "; Expires=" + date.toUTCString();
    document.cookie = name + "=;" + expires + ";";
};

export {
    dateFormat,
    deleteTokenCookie,
    fullNameEnglish,
    fullNameKanji,
    getTokenCookie,
    setTokenCookie
};
