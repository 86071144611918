import { dateFormat, fullNameEnglish, fullNameKanji } from '@/utils/common';
import { isEmpty } from 'lodash';
import * as formatId from '@/utils/idParser';
import moment from 'moment';

export default {
    namespaced: true,
  
    state: {
        exchangeStudentFormData: {
            companyId: '',
            departmentId: '',
            lastDayCourse: '',
            orderNumber: null,
            secondStudentId: '',
            startDate: '受講最終日の翌日が受講開始日となります（自動割り当て）',
            studentId: '',
            newStudentRegistration: false,
            employeeCode: '',
            lastNameKanji: '',
            firstNameKanji: '',
            lastNameAlphabet: '',
            firstNameAlphabet: '',
            newStudentEmail: ''
        },
        exchangeFormValid: false,
        exchangeStudentsDetails: null,
        exchangeWithExistingStudent: false,
        exchangeWithNewStudentRegistration: false,
        loading: false
    },
  
    mutations: {
        SET_EXCHANGE_STUDENT_FORM_DATA(state, payload) {
            state.exchangeStudentFormData = payload;
        },

        SET_EXCHANGE_WITH_EXISTING_STUDENT(state, payload) {
            state.exchangeWithExistingStudent = payload;
        },

        SET_EXCHANGE_WITH_NEW_STUDENT_REGISTRATION(state, payload) {
            state.exchangeWithNewStudentRegistration = payload;
        },

        SET_EXCHANGE_STUDENTS_DETAILS(state, payload) {
            state.exchangeStudentsDetails = payload;
        },

        SET_LOADING(state, payload) {
            state.loading = payload;
        },

        SET_EXCHANGE_FORM_VALID(state, payload) {
            state.exchangeFormValid = payload;
        },

        RESET_EXCHANGE_STUDENT_DATA(state) {
            state.exchangeStudentsDetails = null;
            state.exchangeStudentFormData = {
                companyId: '',
                departmentId: '',
                lastDayCourse: '',
                orderNumber: null,
                secondStudentId: '',
                startDate: '受講最終日の翌日が受講開始日となります（自動割り当て）',
                studentId: '',
                newStudentRegistration: false,
                employeeCode: '',
                lastNameKanji: '',
                firstNameKanji: '',
                lastNameAlphabet: '',
                firstNameAlphabet: '',
                newStudentEmail: ''
            }
        },

        RESET_STUDENT_TO_ADD_FORM(state) {
            state.exchangeStudentFormData.secondStudentId = null;
            state.exchangeStudentFormData.employeeCode = '';
            state.exchangeStudentFormData.lastNameKanji = '';
            state.exchangeStudentFormData.firstNameKanji = '';
            state.exchangeStudentFormData.lastNameAlphabet = '';
            state.exchangeStudentFormData.firstNameAlphabet = '';
            state.exchangeStudentFormData.newStudentEmail = '';
        }
    },
  
    getters: {
        exchangeStudentDetails: (state) => {
            if (!isEmpty(state.exchangeStudentsDetails)) {
                let data  = state.exchangeStudentsDetails;
                let company = data.department.company;
                let department = data.department;
                let student = data.student;
                let studentToRegister = state.exchangeStudentFormData;

                // Old student charge details
                let beforeStudentChargeDetails = {
                    companyId: company.id,
                    companyName: company.name,
                    departmentId: department.id,
                    departmentName: department.name,
                    lastDayOfCourse: dateFormat(data.end_date),
                    oldStudentId: formatId.studentDisplayId(student.student_id),
                    purchaseOrderNumber: data.order_no,
                    studentNameEnglish: fullNameEnglish(student),
                    studentNameKanji: fullNameKanji(student),
                }

                // Details and Course start date of the new existing student
                let courseEndDate = new Date(data.end_date);
                let newCourseStartDate = moment(courseEndDate).add(1, 'd').format("YYYY/MM/DD");

                let existingStudentDetails = data.after_student ? 
                    {
                        afterStudentStartDate: newCourseStartDate,
                        afterStudentId: formatId.studentDisplayId(data.after_student.student_id),
                        afterStudentNameEn: fullNameEnglish(data.after_student),
                        afterStudentNameKanji: fullNameKanji(data.after_student)
                    } :
                    null;

                // Details of new student to register
                let studentToRegisterDetails = studentToRegister.newStudentRegistration ? 
                    {
                        employeeCode: studentToRegister.employeeCode,
                        firstNameAlphabet: studentToRegister.firstNameAlphabet,
                        firstNameKanji: studentToRegister.firstNameKanji,
                        lastNameAlphabet: studentToRegister.lastNameAlphabet,
                        lastNameKanji: studentToRegister.lastNameKanji,
                        newStudentEmail: studentToRegister.newStudentEmail,
                        newStudentRegistration: studentToRegister.newStudentRegistration
                    } :
                    null;

                let afterStudentDetails = data.after_student ? existingStudentDetails : studentToRegisterDetails;

                return Object.assign({}, beforeStudentChargeDetails, afterStudentDetails);
            }

            return [];
        },
    }
}
