 <!-- 
        Alernatively, you can create custom buttons and used the style classes mentioned below.
        Add on main.css if necessary since it's common 
    -->
    <!-- 
        button--disabled  
        button--blue
        button--red
        button--black
        button--loading 
    -->
<template>
    <button
        class="button"
        @click="clicked"
        :class="{
            'button--disabled': disabled,
            'button--blue': btnColorBlue,
            'button--red': btnColorRed,
            'button--black': btnColorBlack,
            'button--loading': isLoading,
            'button--white': btnColorWhite,
        }"
        :disabled="disabled"
    >
        <slot />
        <slot name="button-text" v-if="!loading"></slot>
        <slot name="loading-text" v-if="loading"></slot>
        <font-awesome-icon icon="fa-solid fa-ellipsis fa-beat" v-if="loading" />
    </button>
</template>

<script>
export default {
    name: "AppButton",

    props: {
        color: {
            type: String,
            default: 'blue'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        // pass a props to this component to toggle on/off the loading css
        loading: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isLoadingState: this.loading
        }
    },

    computed: {
        isLoading() {
            return this.loading
        },
        btnColorBlue() {
            return (this.color === 'blue');
        },
        btnColorRed() {
            return (this.color === 'red');
        },
        btnColorBlack() {
            return (this.color === 'black');
        },
        btnColorWhite() {
            return (this.color === 'white');
        }
    },

    methods: {
        clicked() {
            this.isLoadingState = !this.loading
        }
    }
}
</script>

<style scoped>
.button {
    border: none;
    box-shadow: 0px 3px 6px #0000004d;
    cursor: pointer;
    font-family: 'NotoSansJP-Medium';
    font-size: 16px;
    min-width: 160px;
    opacity: 1;
    padding: 12px 16px;
}

.button--loading {
    opacity: 0.8;
}

.button--blue {
    background-color: var(--blue);
    color: var(--white);
}

.button--red {
    background-color: var(--red);
    color: var(--white);
}

.button--black {
    background-color: var(--black);
    color: var(--white);
}

.button--disabled {
    background-color: var(--el-disabled);
    color: var(--gray);
}

.loading-icon {
    font-size: 10px;
}
</style>
