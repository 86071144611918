<template>
    <div class="lms-ex-layout-container">
        <app-layout-header />
        <div class="lms-ex-layout-content"><slot /></div>
        <app-layout-footer />
    </div>
</template>

<script>
import AppLayoutFooter from './AppLayoutFooter';
import AppLayoutHeader from './AppLayoutHeader';

export default {
    name: 'AppLayout',
    components: {
        AppLayoutFooter,
        AppLayoutHeader
    },
};
</script>

<style scoped>
.lms-ex-layout-content {
    box-sizing: border-box;
    margin: auto;
    max-width: 1366px;
    min-height: calc(100vh - 190px);
    padding: 144px 171px 96px;
    z-index: 1;
}

@media screen and (max-width: 1280px) {
    .lms-ex-layout-content {
        padding: 144px 8% 96px;
    }
}
</style>
